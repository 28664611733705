<template>
  <div>
    <div class="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " data-bs-bg="../../../public/assets/img/pageTitle/kurumsal.png">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="ltn__breadcrumb-inner">
              <h1 class="page-title">{{ about.heading }}</h1>
              <div class="ltn__breadcrumb-list">
                <ul>
                  <li><router-link to="anasayfa" ><span class="ltn__secondary-color"><i class="fas fa-home"></i></span> {{ langcontent.anasayfa }}</router-link></li>
                  <li>{{ about.heading }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ltn__about-us-area pb-100 ">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 align-self-center">
            <div class="about-us-img-wrap about-img-left">
              <div class="about-us-img-wrap about-img-left">
                <img :src="about.img" :alt="about.heading">
              </div>
            </div>
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="about-us-info-wrap">
              <div id="app" class="section-title-area ltn__section-title-2--- mb-30">
                <h6 class="section-subtitle section-subtitle-2--- ltn__secondary-color">Uyanık Yatağanlı</h6>
                <h1 class="section-title">{{ about.heading }}</h1>
                <p>
                  {{ about.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.bg-overlay-white-30:before{
  background-image: url("../../../public/assets/img/pageTitle/pg.png");
  background-position-x: center;
}
</style>
<script>
import iso from "../../axios";
export default {
  props:['langurl','langcontent'],
  data() {
    return {
      about: [],
    }
  },
  created() {
    iso.get('about')
        .then(response => {
          this.about = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>